/**
 * Code fragment
 * @module: MenuSecciones
 * @scope: Fama
 * @template: menuItems.blade.php
 */
$(() => {
    function changeMenuEntry(e) {
        //use HOME (fama)-cambio categorias con moseover
        $item = $(e.currentTarget)
        clearInterval();
        let category = $item.data('id');
        let categorySelected;
        $item.closest('.menu-list').find('.list-item').removeClass('isActive');
        $item.addClass('isActive');
        $('.container-collage').removeClass('isActive');

        categorySelected=$('.container-collage .collage[data-id="'+category+'"]');
        categorySelected.closest('.container-collage').addClass('isActive');
    }

    $('.js-change-menuEntry').on('mouseover', e => { changeMenuEntry(e) });
})
